import * as React from 'react';
import Layout from '../../components/layout';
import {
  CurrencyDollarIcon,
  WrenchScrewdriverIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
  const { t } = useTranslation('about');
  const features = [
    {
      name: `${t('innovation.name')}`,
      Icon: CurrencyDollarIcon,
      feature: `${t('innovation.feature')}`,
    },
    {
      name: `${t('development.name')}`,
      Icon: WrenchScrewdriverIcon,
      feature: `${t('development.feature')}`,
    },
    {
      name: `${t('service.name')}`,
      Icon: UserGroupIcon,
      feature: `${t('service.feature')}`,
    },
  ];
  return (
    <Layout pageTitle={t('title')}>
      <div className="py-16 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <h2 className="text-base text-emerald-700 font-semibold tracking-wide uppercase font-display">
              About Smart Power System
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-display">
              {t('introduce.title')}
            </p>
          </div>
          <div className="relative z-10 text-base max-w-prose prose prose-green mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className="text-lg text-gray-500">
              {t('introduce.description')}
            </p>
          </div>
        </div>
      </div>
      <div className="pb-16 bg-white overflow-hidden lg:pb-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <section className="lg:grid lg:grid-cols-3 lg:gap-32 lg:items-start">
            {features.map((item) => (
              <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
                <blockquote className="bg-white rounded-lg shadow-lg">
                  <div className="block bg-green-800 rounded-t-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                    <item.Icon className="block text-amber-400 mx-auto mb-4 w-12 h-12 sm:w-20 sm:h-20" />
                    <h3 className="text-2xl text-center font-bold text-white">
                      {item.name}
                    </h3>
                  </div>
                  <div className="rounded-t-lg px-6 py-8 sm:px-8 sm:pt-10 sm:pb-8 h-36">
                    <div className="relative text-center text-lg text-sky-800 font-medium">
                      <p className="relative text-lg font-semibold">
                        {item.feature}
                      </p>
                    </div>
                  </div>
                </blockquote>
              </div>
            ))}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
